<template>
  <div class="loading-spinner-container">
      <div class="loading-spinner">
          <b-icon icon="arrow-clockwise" animation="spin-pulse"></b-icon>
          <p>{{$t('loading')}}...</p>
      </div>
  </div>
</template>

<script>
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
export default {
    name: 'Loading'
}
</script>

<style scoped>
.loading-spinner-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
    height: 100%;
    background-color: white;
}

.loading-spinner {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
</style>