<template>
  <div>

  <div class="cont" :class="{'rtl': lang == 1, 'ltr': lang==2}">
    
    <!-- <Feedback :state="2" :message="errorMessage"/>  -->
    <div v-if="loading" class="requests-card card rounded">
      <Loading />
    </div>
    <div v-else>
      <h2 v-if="requestSent" style="margin-top:32px">{{ $t("requests") }}</h2>
      <div v-if="postRequest.status" class="card rounded mt-4" style="margin-bottom:400px">
        <div v-if="postRequest.status == 'success' " class="post-request">
          <div class="post-request__icon">
            <i class="pylon-icon-Circle-Checked " style="color: #0BBF04;font-size: 80px;"></i>
          </div>

          <p class="post-request__header"> {{$t('request_feedback.your_request') + ' '}} <span class="post-request__case-id">{{postRequest.case_id}}</span>  {{ ' ' + $t('request_feedback.successfull') }} </p>

          <p class="post-request__subheader"> {{$t('request_feedback.check')}}  
            <a
            :href="`mailto:${company.customer_service_emails[0]}`"
            class="company-info__block-value"
            >
              <span>
                {{ company.customer_service_emails[0] }}
              </span>
            </a>
          </p>

          <a href="/request" class="post-request__redo"> {{$t('request_feedback.another_request')}} </a>
        </div>
        
        <div v-if="postRequest.status == 'error' " class="post-request">
          <div class="post-request__icon">
            <i class="pylon-icon-Close-Circle" style="color: var(--bs-danger);font-size:60px;"></i>
          </div>

          <p class="post-request__header"> {{$t('request_feedback.your_request') + ' '}} <span class="post-request__case-id">{{postRequest.case_id}}</span>  {{ ' ' + $t('request_feedback.failed') }} </p>

          <p class="post-request__subheader"> {{$t('something_went_wrong')}} </p>

          <a href="/request" class="post-request__redo"> {{$t('request_feedback.another_request')}} </a>
        </div>

      </div>

      
      <div v-else class="requests-card card rounded">
        <div class="w-100">
          <!-- <h2 class="mb-4">{{ $t("requests") }}</h2> -->
              <form class="request-form">
                  <p>{{$t('choose_your_request')}}</p>
                  <b-form-select
                    :options="options"
                    :value="null"
                    v-model="request_type"  
                    id="request-option"
                    >
                  </b-form-select>
            </form>
        </div>


        <p v-if="request_type!==null" class="steps-title" >{{$t("needed_docs")}}</p>

        <p v-if="docs.length === 0" style="font-size:16px; font-weight:500 !important; color:#4f4d61">
          <span >{{$t('no_docs_required')}}</span>
        </p>

        <ul class="steps-ul">

          <li v-if="showDownloadButton">
            <p class="mb-1">
              {{$t("paper_1")}}
            </p>
            <button
              class="download mb-1"
              id="docs-download-btn" @click="downloadModel()">
              <i class="pylon-icon-Download"></i>{{$t("Download")}} 
            </button>
          </li>

          <li v-for="(doc,i) in docs" :key="i">
              {{doc.name}}  
            </li>
          
        </ul>
      

        <p v-if="request_type!==null" class="steps-title">{{$t("request_steps")}}</p>

        <ul class="steps-ul" v-if="steps.length !== 0">
            
            <li>
              <form class="" style="display: flex;align-items: baseline;">
                  <label for="customer-email">{{$t('enter_your') + ' ' + $t('email_address')}} :</label>
                  <b-form-group>
                    <b-form-input
                  class="email-input"
                    id="customer-email"
                    v-model="form.email"
                    type="text"
                    :placeholder="$t('email_placeholder')"
                    :state="validation.email"
                    aria-describedby="email-live-feedback"
                  ></b-form-input>

                  <b-form-invalid-feedback id="email-live-feedback">
                    <div class="d-flex flex-column">
                      <span>- {{$t('invalida_email.required')}} </span>
                      <span>- {{$t('invalida_email.format')}}</span>
                    </div> 
                  </b-form-invalid-feedback>
                  </b-form-group>
                 

            </form>
            </li>

            <li v-for="(step,i) in steps" :key="i">
              {{step.steps}}  
            </li>
            
            <li v-if="showProjectLocation">
                <span> {{selectedRequest.set_location_text}} </span>
                <a :href="company.location_map_link" class="project-location">
                  <span>{{company.location_map_link}}</span>
                </a>
            </li>
        </ul>

        <button class="submit mb-1" @click="submit()"> {{$t('submit_request')}} </button>
  
      </div>
    </div>

    

  </div>
</div>
</template>
<script>
import Loading from "@/components/Loading.vue";

import Feedback from "@/components/Feedback.vue";
import { getCookie, validateEmail } from '../utils';
import requestServices from "../services/requests";

export default {
  props:["tab", "company","lang"],
  components: {
    Loading,
    Feedback
  },
 
  data() {
    return {
        values:[],
        options:[],
        requests:[],
        showProjectLocation: false,
        showDownloadButton: false,
        selectedRequest: null,
        docs:[],
        steps:[],
        request_type:1,
        form: {
          email: null,
        },
        validation: {
          email: null
        },
        postRequest: {
          status: null,
          case_id: null,
        },
        notes:"",
        selectedFiles: undefined,
        currentFile: undefined,
        progress: 0,
        message: null,
        meter_options:[],
        meters:[],
        RequestError:false,
        error:false,
        errorMessage:'',
        loading: true,
        requestSent:false,

  }
  },

  methods: {

    downloadModel() {
      requestServices.downloadModel(this.request_type)
        .catch(err =>{ 
        //  console.log(err.message);
          this.error=true;
          this.errorMessage=err.message;
        })
    },


    getDocuments(){  
      requestServices.getDocs(this.lang, this.request_type)
      .then((data)=>
      {
        this.docs = data;
      }).catch(err=>{
        this.error=true;
        this.errorMessage=err.message;
      });
        
    },
    
    getSteps(){
      requestServices.getSteps(this.lang, this.request_type)
      .then((data)=>{
        this.steps=data;
        this.loading = false;
      }).catch(err=>{
        this.loading = false;
        this.error=true;
        this.errorMessage=err.message;
      //  console.log(err)
        });
    },

    submit() {
      if(validateEmail(this.form.email) === false) {
        this.validation.email = false; 
      } else {
        this.validation.email = true;
        requestServices.submitRequest(this.form.email, this.request_type, this.tab.selectedMeter)
        .then( data => {
          this.$emit("remove_nav",false);
          this.requestSent=true;
          if(data) {
            this.postRequest.status = 'success' ;
            this.postRequest.case_id = data; 
          }
          // this.
          // this.$refs.nav.style.display='none'
       
          
        })
        .catch(err => {
          this.postRequest.status = 'error' ;
          this.postRequest.case_id = null
         // console.log(err.message)
        })
      }
    }
  },

  watch:{
    request_type(newType,oldType){
      this.validation.email = null; 
      if(this.request_type!==false){
        this.getDocuments();
      this.getSteps();

      this.selectedRequest = this.options.filter(option => option.value === this.request_type)[0]
      
      this.showDownloadButton = Number(this.selectedRequest.path_count) ? true : false;
      this.showProjectLocation = this.selectedRequest.set_location_text ? true : false;
      }
      

    }
  },

  created(){
    requestServices.getReuests(this.lang)
    // .then(data => {
    //   if(data.length === 0) throw new Error('Data returned from newCustomerCaseType is Empty');
    //   return data; 
    // })
    .then((data) => {
        this.requests = data.map((requests) => {
        return {
          text: requests.text,
          value: requests.pk,
          path_count: requests.path_count,
          set_location_text: requests.set_location_text
        };
      })
      return this.requests
    })
    .then(requests => {
      this.options = requests; 
      this.selectedRequest = requests[0];
      this.request_type = requests[0].value;
    })
    .catch(err=>{
      this.loading = false;
        this.options = [{text: this.$t('no_data_found'), value: false}];
        this.request_type = this.options[0].value;
        console.log(err.message);
    });


  }
};
</script>

<style scoped>

.cont {
  max-width: 856px;
  margin: 0 auto;
}

@media (max-width: 860px){
  .cont {
    padding:0px 20px !important;
  }
}

.requests-card {
  margin-top: 16px;
  margin-bottom: 118px;
  padding: 24px;
  min-height: 300px;
  border-radius: 8px !important;
}

h2{ 
  font-size:32px;
  font-weight:700 !important;
  color:var(--secondary-color);
}

.request-form  {
  margin-bottom: 32px;
}

.request-form span{
  margin-top:8px;
  font-size: 19px;
}

.request-form p{
  margin-bottom: 5px;
    font-size: 16px;
    font-weight: 700 !important;
    color: #002369;

}
.request-form select{
    border-radius: 4px;
    width: 280px;
    max-width: 100%;
    height: 37px;
    padding:0px 8px;
    border: 1px solid #4F4D61;
    outline: none;
}

.request-form select:focus{
  border-color: var(--third-color);
  box-shadow: 0px 0px 5px var(--third-color);
}

.project-location {
  color: #4F4D61;
  text-decoration: underline;
}

.project-location:hover {
  color: var(--main-color);
}

.steps-title {
  margin-bottom: 0px !important;
  font-weight: 700 !important;
  font-size: 16px;
  color: #002369;
}

.steps-ul{
  list-style: decimal;

}

.steps-ul {
  padding: 0px 10px;
}

.steps-ul li{
  font-weight: 500 !important;
  font-size: 16px;
  margin: 12px 8px;
  padding: 0 8px;
  color: #4F4D61;
}

.steps-ul li p{
  font-size: 16px;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;

}

.download, .submit{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
    width:150px;
    text-align: center;
    
    padding: 6px 10px;
    border-radius: 5px;
    font-size: 16px;
}


.download {
  color: var(--main-color);
  background: white;
  border: 1px none;
}
.email-input{
  max-width: 240px;
  height:32px;
  margin: 0px 8px;
}
.submit {
  width: 240px;
  color: white;
  background: var(--main-color);
  border: 1px solid var(--main-color);
  font-weight: 600;
  align-self: end;
}

.download:disabled {
  color: var(--bs-gray-500);
} 

.submit:disabled {
  background-color: white;
  border: 1px solid var(--bs-gray-500);
  color: var(--bs-gray-500);
}



.steps-ul li p{
-webkit-box-orient: vertical;
-webkit-box-direction: normal;
    -ms-flex-direction: column;
        flex-direction: column;

}


.post-request {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.post-request__icon {
  margin-bottom: 24px;
}

.post-request__header {
  font-weight: 700 !important;
  font-size: 20px;
  text-align: center;
  color: #002369;
}

.post-request__case-id {
  color: #007EFF;
  font-weight: 700 !important;
  font-size: 20px;
}

.post-request__subheader {
  font-weight: 500 !important;
  font-size: 16px;
  text-align: center;
  color: #002369;
}

.post-request__redo {
  margin-top: 56px;
  font-weight: 600 !important;
  font-size: 14px;
  line-height: 17px;
  text-decoration: underline;
  color: var(--main-color);
}
</style>



    
