<template>
  <div class="navSmall" ref="nav" :class="{ 'rtl': lang ==1, 'ltr': lang ==2 }">
    <div class="nav-s" >
     <h2>{{ pageTitle }}</h2>
    <div class="inner-tabs" >
       <!-- <p style="" class="unit-code">{{$t("my_meters")}}</p> -->
      <b-tabs  class="small-tabs" align="center" active-nav-item-class="{background}">
      
        
        <b-tab
          :title="item.code"
          v-for="item in tabsData"
          :key="item.code"
          @click="changekey(item.code,item.account_reference,item.code?true:false)"
          :active="item.code==tabs.selectedTab"
        >
          <template #title>
            <span>
              <i v-if="item.mdm_utilities == '1'" class="pylon-icon-Electric"></i>
              <i v-if="item.mdm_utilities == '2'" class="fas fa-burn"></i>
              <i v-if="item.mdm_utilities == '3'" class="pylon-icon-Water"></i>
              {{item.mdm_utilities==='1'?$t('electricity'):$t('water')}}: {{item.code?item.code:item.account_reference}} 
             
            </span>
          </template>
        </b-tab>

      </b-tabs>
         
    </div>
     <!-- <p class="unit-code" >{{$t("account_reference")}} {{tabs.account_reference}}</p> -->
    </div>
  </div>
</template>
<script>

export default {
  data(){
    return{
      navbar:null,
      sticky:null,
      
    }
  },
  props: {
    tabsData: {
      default: () => [],
    },
    tabs:{
      default: () => [],
    },
    lang:{
      default: () => [],
    },
    pageTitle:{
      default: () => {}
    }
  },

  methods: {
    changekey(code,account_reference,code_Found) {
      this.$emit("changekey", {code:code,account_reference:account_reference,code_Found:code_Found});
    },

  },
 created() {
   
    window.addEventListener("scroll", this.getSticky);
    
  },

  destroyed() {
    window.removeEventListener("scroll", this.getSticky);
  },
};
</script>
<style>

.navSmall {
  max-width: 856px;
  margin: 0px auto;
  margin-top: 32px;
}

.nav-link.active {
  color: var(--inverse-text-color) !important;
  background-color: var(--main-color) !important;
  border: 1px solid transparent;
 
}

.nav-link {
  color: var(--inverse-text-color) !important;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.nav-link:hover {
  background-color: var(--main-color) !important;
  border-radius:5px 5px 0px 0px;
}
.nav-link:hover .nav-link i{
 color: var(--main-color) !important;

}
.nav-tabs{
  border-bottom:0px !important;
  margin: 0px -5px;

} 

.navSmall .small-tabs {
    border-bottom: 0px !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
}
 .navSmall .small-tabs .nav-item{
  
background: transparent !important;
}

.navSmall .small-tabs .nav-link {
  color: #4F4D61 !important;
  background-color: #F0F0F0;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  padding: 5px 10px;
  margin: 0px 5px;
  border-radius: 4px !important;
  height: 37px;
  padding-top: 7px;
  font-size: 14px;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
}


.navSmall .small-tabs .nav-link.active {
  color: var(--inverse-text-color) !important;
  background-color: var(--main-color) !important;
  border-radius: 4px !important;
  border:0px;
  height:37px;
  padding-top: 8px;
}
.navSmall .small-tabs .nav-link:hover {
  background-color: var(--main-color) !important;
  color: white !important;
  border-color: var(--main-color);
}

@media (max-width:995px){
.nav-s{
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
}
@media (max-width:860px){
 .inner-tabs{
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
 }
 .navSmall .small-tabs{
 margin:0px;
 }
.nav-s{
margin: 0px 20px;
}
}
@media (max-width:600px){

.small-tabs ul{
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
}
.small-tabs li{
    margin-bottom: 5px;
}
.small-tabs ul .nav-item {
    font-size: 16px !important;
}
}

.sticky {
  z-index: 500;
    background: white;
    position: fixed;
    top: 0;
    margin: 0px;
}
.nav-s ul{
  padding:0px !important}
</style>