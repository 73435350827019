<template>
  <div class="container profile" :class="{ rtl: lang == 1, ltr: lang == 2 }" style="max-width: 856px;padding: 0px;margin-top:16px;margin-bottom: 200px;" >
    <b-row style="margin: 0;">
      <b-col class="profileInfo" :class="{ 'full-width': company.notfication_settings == false }">
        <!-- <b-tabs align="center" no-key-nav> -->
          <!-- <b-tab :title="$t('personal_info')" :lang="lang" > -->
            <personal-info v-on:update-user-name="updateUserName" :lang="lang" :company="company" :meter_id='selectedMeter' :propsObj="propsObj" />
          <!-- </b-tab> -->
          <!-- <b-tab  v-if="company.notfication_settings" :title="$t('notification_settings')"  :lang="lang"> -->
            
            <notification-settings  v-if="company.notfication_settings" :meter_id='selectedMeter' :lang="lang" :propsObj="propsObj" :company="company" :class="{'rtl': lang==1, 'ltr': lang==2}" />
       
          <!-- </b-tab> 
        </b-tabs> -->
      </b-col>
      
    </b-row>
  </div>
</template>
<script>
import userServices from "../services/user";
import meterservices from "../services/meters";
import PersonalInfo from "../components/Profile/PersonalInfo.vue";
import NotificationSettings from "../components/Profile/NotificationSettings.vue";


export default {
  components: {
    PersonalInfo,
    NotificationSettings
  },
  props:["company",'tab','lang'],
  data() {
    return {
      userName: '',
      userInfo:[],
      meterInfo:[],
      propsObj:{},
      tabsData: [],
      selectedTab: "",
      selectedMeter: {},
    };
  },
  watch:{
   'tab'(){
     this.getChanged();
  } , 
   'selectedTab'(newTab,old){
     this.initialize();
   }
  },
   created() {
    if(this.tab !=0){
      this.getChanged();
    }
  },
  methods: {
    getChanged(){
    this.tabsData=this.tab.tabsData;
    this.selectedTab=this.tab.selectedTab;
    this.selectedMeter=this.tab.selectedMeter;

   },
    updateUserName(name) {
      this.userName = name
    },
    initialize(){
      userServices.getUser()
      .then(data =>{       
            this.userInfo=data; 
      })
      .then(() =>{   
        meterservices.getSingleMeter(this.selectedMeter) //be handeled later for more than meter gas-water-electric)
          .then((data) => {
              this.meterInfo=data;
        }).then(()=>{
          this.propsObj={userInfo:this.userInfo,
                        meterInfo:this.meterInfo
                       } 
        })
        .catch(err=>{
            console.log('not found')
          }) 
      }) 
    },
  }
};
</script>

<style>
@media (max-width: 860px){
  .container.profile{
    padding:0px 20px !important;
  }
}

h2{
  font-size:32px;
  font-weight: 700 !important;
  color:#002369;
  margin-bottom:32px;
}
@media (max-width:500px) {
.tabs .nav-item{
  font-size: 11px !important;
}
}
.card-header {
  display: none;
}
.card {
  padding: 30px;
}

.profileImg {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 328px;
  margin-bottom: 48px;
  /* justify-content: center; */
}
.profile-icon {
  font-size: 4rem;
  color:#4F4D61;
  margin-top:12px;
  margin-bottom: 18px;
  text-align: center;

}
.profileUserName{
  color: #4F4D61;
  font-size:20px ;
  font-weight:600 !important;
  margin-bottom: 24px;
  text-align: center;
}
.card-body {
  display: none;
}
.inpSpace {
  font-size: 14px;
  font-weight: bold;
}
.inpSpace label {
  margin-bottom: 10px;
}
.note {
  font-size: 12px;
  padding-top: 10px;
}
.nav-item{
    background-color: var(--secondary-color) !important;
    color: var(--inverse-text-color);
    border: none;
}
.profileInfo .nav-tabs
{
  width:100%;
  background: white !important;
  -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
          justify-content: space-between !important;
  margin-bottom: 16px;
  padding:0px;
}

.profileInfo .nav-tabs .nav-item{
  width:49%;
    background: white !important;
}
.profileInfo.full-width .nav-tabs .nav-item{
  width:100%;
}
.profileInfo .nav-tabs .nav-link{
  width: 100%;
    height: 40px;
    background: #F0F0F0;
    border-radius: 4px !important;
    font-weight: 500 !important;
    font-size: 14px;
    line-height: 24px;
    padding: 8px;
    text-align: center;
    color: #4F4D61 !important;
color: #4F4D61;
}
.profileInfo .nav-tabs .nav-link.active,.profileInfo .nav-tabs .nav-link:hover{
  color: white !important;
  font-weight: 700 !important;
  border-radius: 4px !important;
  width: 185px;
}
/* .profileImg,.profileInfo{
  border: 1px solid #DFDFDF;
    border-radius: 8px;
    padding: 24px;
} */

.profileInfo{
  /* height: 507px; */
  padding:0px;
  margin-bottom: 48px;
}

.profile-tabs .nav-tabs{
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 0px;
}
.profile-tabs .nav-item{
  margin-bottom: 8px;
  background: white !important;
}

.profile-tabs .nav-link{
  font-family: 'Montserrat';
    padding: 12px 8px;
    height: 48px;
    background: #F0F0F0;
    
    color: #4F4D61 !important;
    border-radius: 4px;
    
}
.profile-tabs .nav-link.active {
  background: var(--main-color);
  color:white !important;
}
.profile-tabs .nav-link span.text-container{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin: auto;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 50%;
    text-align: center;
}

@media(min-width:1200px) and (max-width: 1400px) {
  .profile-tabs .nav-link span.text-container{
    width:60%;
  }

}
@media (min-width:993px) and (max-width: 1199px)  {
  .profile-tabs .nav-link span.text-container{
    width:70%;
  }
}
@media (min-width:768px) and (max-width: 992px) {
  .profile-tabs .nav-link span.text-container{
    width:97%;
  }

}
.profile-tabs .nav-link span.text{
  width: 85%;
    text-align: start;
  font-weight: 500 !important;
    font-size: 14px;

}
.profile-tabs .nav-link.active span.text,.profile-tabs .nav-item:hover .nav-link span{
  font-weight: 700 !important;
  color:white !important;

}
.profile-tabs .nav-link i{
  width:10%;
  font-size: 1rem;
}
/* @media (min-width:992px){
  .col-lg-7 {
    width: 64.8%;
}

} */
.profileInfo{
  border: 1px solid #DFDFDF;
  border-radius: 8px;
  padding: 24px;
}
</style>