<template>
  <div>
    <LoginNavbar :isApply="false" :company="company" :lang="lang"/>
    <div class="cont" :class="{'rtl': lang == 1, 'ltr': lang==2}">

      <div class="note">
        <router-link to="/login" class="back">
            <i  style="font-size: 24px" :class="{'pylon-icon-ArrowRight': lang == 1, 'pylon-icon-Arrow-Left': lang==2}"></i>
            {{ $t("back_to_login")}}
        </router-link>  
        <h2 class="mb-4">{{ $t("requests") }}</h2>
        <p>{{$t('submit_request_for_registered_user_note_1')}}<router-link to="/login"> {{$t('login')}} </router-link> {{$t('submit_request_for_registered_user_note_2')}}</p>
        </div>
      
      <div v-if="loading" class="requests-card card rounded">
        <Loading />
      </div>
      <div v-else class="requests-card card rounded">
  
        <Feedback v-if="error" :state="2" :message="errorMessage"   @unmountFeedComponent="error=false"/>
        <div class="w-100">


          <form class="request-form">
              <p>{{$t('choose_your_request')}}</p>
              <b-form-select
                :options="options"
                :value="null"
                v-model="request_type"
                id="request-option"
                >
              </b-form-select>
          </form>

        </div>


        <p class="steps-title">{{$t("needed_docs")}}</p>
        
        <p v-if="docs.length === 0 " style="font-size: 16px;font-weight: 500 !important;">
          <span>{{$t('no_docs_required')}}</span>
        </p>
        
        <ul  class="steps-ul">
          <li v-if="showDownloadButton">
            <p class="mb-1">
              {{$t("paper_1")}}
            </p>

            <button
              class="download mb-1"
              id="docs-download-btn" @click="downloadModel()">

              <i style="margin: 0px 4px;" class="pylon-icon-Download"></i>{{$t("Download")}} 
            </button>
          </li>

          <li v-for="(doc,i) in docs" :key="i">
              {{ doc.name}}
          </li>
          
        
        </ul>
      
        <p class="steps-title">{{$t("request_steps")}}</p>
    
        <ul class="steps-ul" v-if="steps.length !== 0">
            <li v-for="(step,i) in steps" :key="i">
              {{ step.steps }}
            </li>
            <li v-if="showProjectLocation">
                <span> {{selectedRequest.set_location_text}} </span>
                <a :href="company.location_map_link" class="project-location">
                  <span>{{company.location_map_link}}</span>
                </a>
            </li>
        </ul>

        <ErrorHandler v-if="RequestError" :error="errorMessage" />

      </div>

    </div>

    <Footer :isApply="apply" :company="company" :lang="lang"/>

  </div>
</template>

<script>
// Components
import Footer from '../components/Footer.vue';
import LoginNavbar from '../components/Login/LoginNavbar.vue';
import ErrorHandler from "@/components/ErrorHandler.vue";
import Feedback from "@/components/Feedback.vue";
import Loading from "@/components/Loading.vue";

// Services
import requestServices from "../services/requests";


export default {
  components: {
    Feedback,
    Loading,
    Footer,
    ErrorHandler,
    LoginNavbar,
    Loading
  },

  props:["company","lang"],
  data() {
    return {
      values: [],
      options: [],
      requests: [],
      showDownloadButton: false,
      showProjectLocation: false,
      selectedRequest: null,
      request_type: 1,
      docs: [],
      docs_paths: [],
      steps: [
          "National ID",
          "paper 1",
          "paper 2"
      ],
      apply: true,
      RequestError: false,
      errorMessage: '',
      error: false,
      loading: true,
    };
  },

  methods: {

    downloadModel() {
      requestServices.downloaddNewCustomerFiles(this.request_type)
        .catch(err => {
          // console.log(err.message);
          this.error=true;
          this.errorMessage=err.message;
        })
    },

    getDocuments(){  
      requestServices.getNewCustomerRequestDocs(this.lang,this.request_type)
      .then( data => { this.docs=data; })
      .catch(err=>{
        this.docs = [];
        // console.log(err.message);
      }).catch(err => {
      // console.log(err.message);
      this.error=true;
      this.errorMessage=err.message;
    });
    },

    getSteps(){
      requestServices.getNewCustomerRequestSteps(this.lang, this.request_type)
      .then( data =>{
        this.steps=data;
        this.loading = false; 
      })
      .catch(err=>{
        this.loading = false; 
        this.steps = [];
        this.error=true;
        this.errorMessage=err.message;
        // console.log(err.message);
      });
    }
  },

  watch:{
    request_type(newType){
      if(newType !== false) {
        this.getDocuments();
        this.getSteps(); 
        this.selectedRequest = this.options.filter(option => option.value === this.request_type)[0];    
        this.showDownloadButton = Number(this.selectedRequest.path_count) ? true : false;
        this.showProjectLocation = this.selectedRequest.set_location_text ? true : false; 
      }
      
    }
  },

  created(){
    requestServices.getNewCustomerRequests(this.lang)
    .then(data => {
      if(data.length === 0) throw new Error('Data returned from newCustomerCaseType is Empty');
      return data; 
    })
    .then( data => {
      this.requests = data.map(request => {
        return {
          text: request.text,
          value: request.pk,
          path_count: request.path_count,
          set_location_text: request.set_location_text
        };
      });
      return this.requests
    })
    .then(requests => {
      this.options = requests;
      this.request_type = requests[0].value;
      this.selectedRequest = requests[0];
    })
    // .then( () => {
    //   this.getDocuments();
    //   this.getSteps();
    // })
    .catch(err=>{
        this.loading = false;
        this.options = [{text: this.$t('no_data_found'), value: false}];
        this.request_type = this.options[0].value;
        // console.log(err.message);
    });
  }
};

</script>

<style scoped>

.cont {
  max-width: 856px;
  margin: 0 auto;
  margin-top: 32px;
  padding:0px 20px;
}


@media (max-width: 600px) {
  .cont {
    max-width: 90%;
  }
}

.note{
  padding-top:0px;
}
.note p{
  font-weight: 500 !important;
  font-size: 14px;
  color: #767676;
  max-width: 536px;
}

.requests-card {
  margin-bottom: 195px;
  padding: 24px;
  min-height: 300px;
  border-radius: 8px !important;
  border: 1px solid #DFDFDF;
}


h2{ 
  font-size:32px;
  font-weight:700 !important;
  color:#002369;
  margin-bottom: 8px !important;
}

.request-form  {
  margin-bottom: 32px;
}

.request-form span{
  margin-top:8px;
  font-size: 19px;
}

.request-form  p{
  margin-bottom:5px;
  font-size:18px;
  font-weight:500 !important;
  color:var(--secondary-color);
}
.request-form select{
    border-radius: 4px;
    width: 280px;
    max-width: 100%;
    height: 37px;
    padding: 0px 8px;
    border: 1px solid #4F4D61;
    outline: none;
}

.request-form select:focus{
  border-color: var(--third-color);
  box-shadow: 0px 0px 3px var(--third-color);
}
.steps-ul {
  list-style: decimal;
  padding:0px 16px;
  color: #4F4D61;
  font-weight: 500 !important;
  font-size: 14px;
  margin-bottom: 0px !important;
}

.steps-ul li{
  font-weight: 500 !important;
  font-size: 16px;
  margin: 12px 8px;
  padding: 0 8px;
  color: #4F4D61;
}
.steps-ul li p{
  font-size: 16px;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;

}

.project-location {
  color: #4F4D61;
  text-decoration: underline;
}

.steps-title,.request-form p{
  margin-bottom: 8px ;
  font-weight: 700 !important;
  font-size: 18px;
  color: #002369;
}
.request-form p{
  margin-bottom: 16px !important;
}
.download{
    width:150px;
    text-align: center;
    color: var(--main-color);
    background: white;
    border: none;
    padding: 6px 10px;
    border-radius: 5px;
    font-size: 16px;
}

.download:disabled {
  background-color: white;
  border: none;
  color: var(--bs-gray-500);
}



.steps-ul li p{
-webkit-box-orient: vertical;
-webkit-box-direction: normal;
    -ms-flex-direction: column;
        flex-direction: column;
}


.back{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 600 !important;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
  color: #767676;
  max-width: 200px;
  /* margin-bottom: 8px; */
}
</style>



    
