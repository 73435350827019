<template>
  <div class="home-nav" :class="{'nav-font-size': lang ==2 }">
    <b-navbar toggleable="lg" type="dark" class="navBig" :class="{ 'rtl': lang ==1, 'ltr': lang ==2 }">
        <img v-if="lang == 2" class="logo " :src="require(`@/Projects/${company.logo_path.english}`)"/>
        <img v-if="lang == 1" class="logo " :src="require(`@/Projects/${company.logo_path.arabic}`)" />
      <b-navbar-toggle target="nav-collapse" >
        <template #default="{ expanded }">
          <b-icon v-if="expanded" icon="chevron-bar-up"></b-icon>
          <b-icon v-else icon="chevron-bar-down"></b-icon>
        </template>
      </b-navbar-toggle>


      <b-collapse id="nav-collapse" is-nav class="row main-flex"  :class="{ 'rtl': lang ==1, 'ltr': lang ==2 }">
        <b-navbar-nav v-if="auth" class="tabs-page">
          <b-nav-item :to="{ name: 'Home' }">
            {{ $t("home") }}
           </b-nav-item>
          <b-nav-item v-if="company.requests" :to="{ name: 'Request' }">
            {{ $t("requests") }}
          </b-nav-item>
          <b-nav-item to="Guide">
            {{ $t("meters_screens_guide") }}
          </b-nav-item>

          <b-nav-item v-if="company.faq" to="FAQ">
            {{ $t("faq") }}
          </b-nav-item>
  
          <b-nav-item to="Profile" >
               {{ $t('Profile') }}
          </b-nav-item>    
        </b-navbar-nav>


        <div class="d-flex inquiry-icon-flex" >

          <InquiryComplaints v-if="auth && company.raise_complaint_inquiry" :meterProps="meterProps" :lang="lang"/>
          <div class='verttical-separator'></div>
          <div class="notification-flex">
            <b-navbar-nav v-if="auth"  style="padding: 0;display: contents">
                      

                <!-- <b-nav-item v-if="company.notifications" href="#" :class="{'mt-1': lang==1}">
                  <i class="far fa-bell"></i>
                  
                </b-nav-item> -->
                 
                 <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                  style='position:relative'
                  class="drop"
                >
                  <template #button-content>
                    <i :class="{'pylon-icon-Lang rtl': lang ==1, 'pylon-icon-Lang': lang==2 }"></i>
                    <span  v-if="lang==2">{{company.PortalLangs.secondLang}}</span>
                    <span style=" margin-top: -8px;"  v-if="lang==1">{{company.PortalLangs.firstLang}}</span>
                  </template>
                  <b-dropdown-item  href="#" @click="changeLang(2)"
                    >{{company.PortalLangs.secondLang}}</b-dropdown-item
                  >
                  <b-dropdown-item href="#" @click="changeLang(1)"
                  >{{company.PortalLangs.firstLang}}</b-dropdown-item
                  >
                </b-dropdown>
          </b-navbar-nav>
         
          </div>       
          <div class='verttical-separator'></div>
          <button class="btn logout-button text-center" :class="{'nav-font-size': lang ==2 }" id="logout-btn" @click="handleLogout">
           <i class="pylon-icon-Logout"></i>
            {{$t('logout')}}</button>
        </div>

      </b-collapse>
    </b-navbar>
    <nav-bar-s 
      v-if="tabsData.length && contain_nav"
      :tabsData="tabsData" 
      :tabs="tab"
      @changekey="updateData" 
      :lang="lang"
      :pageTitle="pageHeader"
    />
    <Feedback
          v-if="feedback.show"
          :state="feedback.state"
          :message="feedback.message"
          :lang="lang"
          @unmountFeedComponent="feedback.show=false"
        />
  </div>
</template>
<script>
import InquiryComplaints from "./InquiryComplaints.vue";
import Feedback from "../Feedback.vue";
import services from '@/services/authentication'
import langServices from '@/services/lang';
import userServices from '@/services/user'
import { getCookie } from "@/utils";
import NavBarS from "./NavBarMeterTabs.vue";
import { ConcatSource } from "webpack-sources";


export default {
  components: {
    InquiryComplaints,
    NavBarS,
    Feedback
},
  props: {
    tab: {
      default: () => false,
    },
    auth: {
      default: () => false,
    },
    company: {
      default: () => {},
    },
    contain: {
      default: () => {},
    },
    lang: {
      default: () => {},
    },
    pageTitle:{
      default: () => {},
    }
  },
  data() {
    return {
      tabsData: [],
      selectedTab: "",
      selectedMeter: {},
      sublocation:"",
      req_trx_master: null,
      payment_mode: null,
      enforce_post_paid_mode:false,
      unit:"",
      errorMessage: "",
      contain_nav:false,
      pageHeader:'',
      meterProps:"",
      feedback:{
        show:false,
        message:"",
        state:""
      }
    }
  },
  watch:{
    contain(){
     this.contain_nav=this.contain;
    },
    pageTitle(){
     this.pageHeader=this.pageTitle;
    },
    'tab'(){
        this.tabsData=this.tab.tabsData,
        this.selectedTab=this.tab.selectedTab,
        this.selectedMeter=this.tab.selectedMeter,
        this.req_trx_master=this.tab.req_trx_master,
        this.payment_mode=this.tab.payment_mode;
        this.account_reference=this.tab.account_reference;
        this.enforce_post_paid_mode=this.tab.enforce_post_paid_mode;
        this.unit=this.tab.unit;
   },
    selectedTab(){
      this.sendCahangesToApp();
   },

    },
   
  methods: {
    scroll() {
      const element = document.getElementById("Profile");
      element.scrollIntoView({ behavior: "smooth" });
    },

    changeLang(langOption) {
      langServices.updateLang(langOption)
      .then(() => {this.$i18n.locale = langOption==1?'firstLang':'secondLang' })
      .then(() => this.$router.go()).catch(err=>{
        this.feedback.show=true;
        this.feedback.message=err.message;
        this.feedback.state=2;
      })
    },
    sendCahangesToApp(){
      var props_container={
        tabsData:this.tabsData,
        selectedTab:this.selectedTab,
        selectedMeter:this.selectedMeter,
        req_trx_master:this.req_trx_master,
        payment_mode:this.payment_mode,
        enforce_post_paid_mode:this.enforce_post_paid_mode,
        account_reference:this.account_reference,
        unit:this.unit
      }
      this.$root.$emit('changedTabContainer',props_container)
    },
    handleLogout() {
     services.logout()
    },
    updateData(selected_obj_options) {
        this.selectedTab = selected_obj_options.code_Found?selected_obj_options.code:selected_obj_options.account_reference;
        const temp = this.tabsData.filter(meter => meter.code? meter.code == selected_obj_options.code :meter.account_reference ==selected_obj_options.account_reference)
        this.selectedMeter = temp[0].req_pk;
        this.account_reference=temp[0].account_reference;
        // if code of meter not inserted (null) enforce payment mode tp be post paid
        this.enforce_post_paid_mode=selected_obj_options.code_Found?false:true;
        this.unit=temp[0].unity;
    },
  },
 async created(){
   await userServices.getUser()
    .then((response) => {
      this.meterProps=response;
      this.tabsData = response;
      if(this.tabsData.length) {
        this.selectedTab = this.tabsData[0].code?this.tabsData[0].code:this.tabsData[0].account_reference;
        this.selectedMeter = this.tabsData[0].req_pk;
        this.account_reference=this.tabsData[0].account_reference;
        this.enforce_post_paid_mode=this.tabsData[0].code?false:true;
        this.unit=this.tabsData[0].unity;

      } else {
        this.selectedTab = false
        this.errorMessage = "User Not found"
      }
    })
    .catch(err => {
      this.selectedTab = false
      this.errorMessage = err.errorMessage || "Something went wrong";
      
    });

    this.pageHeader  = this.$t(this.$route.meta.pagetTitle);
    this.contain_nav = this.$route.meta.containsMeterNav;

  }
};
</script>

<style >
.navBig {
  background-color: var(--secondary-color);
  color: var(--inverse-text-color);
  padding: 10px 20px;
}
.main-flex{
 margin-left: 10px; 
 margin-right: 10px;
}

.logo{
  margin-top: -4px ;
  width: 143px;
  height: 64px;
  -o-object-fit: contain;
     object-fit: contain;

}
.navBig.navbar-dark .navbar-nav .nav-link,.navbar-nav .nav-link:hover{
    color: #E0E0E0 !important;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    background: transparent !important;
}

.navBig.navbar-dark .navbar-nav .nav-link.router-link-exact-active{
  color: #fff !important;
  font-weight: 600;
  position: relative;
}
.navBig.navbar-dark .navbar-nav .nav-link.router-link-exact-active:after{
  position: absolute;
    content: '';
    height: 2px;
    width: 20px;
    background: white;
    top: 82%;
    left: 9px;

}
.navBig.navbar-dark.rtl .navbar-nav  .nav-link.router-link-exact-active:after{
    right: 9px;
    left:unset;

}
#nav-collapse {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.navBig.ltr .navBigFonts{
  margin-left: 35%;
}
.navBig.rtl .navBigFonts{
  margin-right: 25%;
}
.body:lang(1) {
  direction: rtl;
}
.logout-button,.logout-button:hover {
  color: #FFF;
  font-size: 15px;
  font-weight: 500;
  background: transparent;
  border: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap:4px;
}

.navBig .dropdown-toggle::after{
  display: none !important;
}

.nav-font-size{
  font-size: 15px;
}
.tabs-page{
    width:600px;
  }
.inquiry-icon-flex{
    width: 430px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 18px;
}
.inquiry-icon-flex button{
  padding: 0px;
}
.verttical-separator{
    width: 0.5px;
    height: 24px;
    background: #E0E0E0;
}
@media (max-width:992px) {

.navbar-dark .navbar-brand ,.main-flex{
  margin-right: 0px;
    margin-left: 0px;
 }
}
@media (max-width:1193px){
  .tabs-page{
    width:500px;
  }
  .main-flex .inquiry-icon-flex{
    width:380px;
    padding: 0px;
  }

}
@media (min-width:1146px) and (max-width:1193px){
  .tabs-page{
    width:450px;
    padding:0px;
  }
  .inquiry-icon-flex{
    width:400px;
    padding-left:0px;
    padding-right: 0px;
  }
}
@media (min-width:992px) and (max-width:1145px){
  .tabs-page, .inquiry-icon-flex{
    width:370px;
    font-size: 14px;
    padding:0px !important;
  }
 .logo{
  margin:0px 20px !important;
 }
 .navbar-dark .navbar-brand ,.main-flex{
  margin-right: 0px;
    margin-left: 0px;
 }
 .logout ,.inquiry-btn {
  font-size: 14px;
 }

}
.dropdown-menu.show{

position: absolute;
top: calc(100% + 8px);
left: 30%;
z-index: 500;
width: 50px !important;

}
.ltr .dropdown-menu.show{
left:unset;
right: 8%;
}
@media (max-width:600px){
  .main-flex .inquiry-icon-flex{
    padding: 0px;
    width:90%;
  }
} 
@media (max-width:460px){
  .inquiry-icon-flex{
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
.inquiry-btn{
      white-space: wrap;
  }
  .navBig .dropdown-toggle::before{
    content:unset;
  }
  .home-nav .dropdown-toggle.btn-link{
    margin:0px;
  }
}


.notification-flex ul .nav-item,.notification-flex .drop{
  width:40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.dropdown-toggle.btn-link{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap:4px;
  color:white;
  -webkit-box-flex:0;
      -ms-flex:0;
          flex:0;
} 

</style>